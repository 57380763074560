var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"width":"650px","centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"项目配置"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"运营商账户"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['carrier_account_id', {
                rules: [
                  { required: true, message: '请选择运营商账户' }
                ]
              }]),expression:"['carrier_account_id', {\n                rules: [\n                  { required: true, message: '请选择运营商账户' }\n                ]\n              }]"}],attrs:{"allow-clear":"","loading":_vm.loadingCarrierAccountOptions},on:{"focus":function($event){return _vm.fetchCarrierAccountOptions(_vm.data.carrier_type_slug)},"change":_vm.handleCarrierAccountChange}},_vm._l((_vm.carrierAccountOptions),function(option){return _c('a-select-option',{key:option.id,attrs:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"运营商套餐"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['carrier_product_id', {
                rules: [
                  { required: true, message: '请选择运营商套餐' }
                ]
              }]),expression:"['carrier_product_id', {\n                rules: [\n                  { required: true, message: '请选择运营商套餐' }\n                ]\n              }]"}],attrs:{"loading":_vm.loadingCarrierProductOptions,"allow-clear":""},on:{"change":_vm.handleCarrierProductChange}},_vm._l((_vm.carrierProductOptions),function(option){return _c('a-select-option',{key:option.id,attrs:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"流量属性"}},[_c('a-input',{attrs:{"disabled":""},model:{value:(_vm.trafficProperty),callback:function ($$v) {_vm.trafficProperty=$$v},expression:"trafficProperty"}})],1),(_vm.directionalShow)?_c('a-form-item',{attrs:{"label":"VPDN分组ID"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['vpdn_id', {
                rules: [
                  { required: true, message: '请选择VPDN分组ID' }
                ]
              }]),expression:"['vpdn_id', {\n                rules: [\n                  { required: true, message: '请选择VPDN分组ID' }\n                ]\n              }]"}],attrs:{"loading":_vm.loadingVpdnOptions,"allow-clear":""},on:{"change":_vm.handleVpdnChange}},_vm._l((_vm.vpdnOptions),function(option){return _c('a-select-option',{key:option.vpdn_id,attrs:{"value":option.vpdn_id}},[_vm._v(" "+_vm._s(option.vpdn_id)+" ")])}),1)],1):_vm._e(),(_vm.directionalShow)?_c('a-form-item',{attrs:{"label":"VPDN分组名称"}},[_c('a-input',{attrs:{"disabled":""},model:{value:(_vm.vpdnMap.name),callback:function ($$v) {_vm.$set(_vm.vpdnMap, "name", $$v)},expression:"vpdnMap.name"}})],1):_vm._e(),(_vm.directionalShow && _vm.form.getFieldValue('vpdn_id')!='99999_E'&& _vm.form.getFieldValue('vpdn_id')!='99999_A')?_c('a-form-item',{attrs:{"label":"VPDN IP地址"}},[_c('a-textarea',{staticStyle:{"height":"100px"},attrs:{"disabled":"","placeholder":""},model:{value:(_vm.vpdnMap.ips),callback:function ($$v) {_vm.$set(_vm.vpdnMap, "ips", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"vpdnMap.ips"}}),_c('div',[_vm._v("总数："+_vm._s(_vm.vpdnMap.ips_count))])],1):_vm._e(),_c('a-form-item',{attrs:{"label":"实名认证"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_need_verified',{
                initialValue: false,
                rules: [
                  { required: true, message: '请选择实名认证' } ]
              }]),expression:"['is_need_verified',{\n                initialValue: false,\n                rules: [\n                  { required: true, message: '请选择实名认证' },\n                ]\n              }]"}]},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" 是 ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" 否 ")])],1)],1),_c('a-form-item',{attrs:{"label":"是否限额"}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-form-item',{style:({ display: 'inline-block', width: '15%', marginBottom: 0 })},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_limit_usage',{
                    valuePropName: 'checked'
                  }]),expression:"['is_limit_usage',{\n                    valuePropName: 'checked'\n                  }]"}]})],1),(_vm.isLimitUsage)?_c('a-form-item',{style:({ display: 'inline-block', width: '65%', marginBottom: 0 })},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['limit_usage_value', {
                      initialValue: _vm.data.limit_usage_value,
                      rules: [
                        { required: _vm.isLimitUsage, message: '请输入限额' }
                      ]
                    }]),expression:"['limit_usage_value', {\n                      initialValue: data.limit_usage_value,\n                      rules: [\n                        { required: isLimitUsage, message: '请输入限额' }\n                      ]\n                    }]"}],staticStyle:{"width":"200px"},attrs:{"min":0.01,"max":10000000,"step":0.01,"precision":2}})],1)],1):_vm._e(),(_vm.isLimitUsage)?_c('a-form-item',{style:({ display: 'inline-block', width: '20%', marginBottom: 0 })},[_c('a-space',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['limit_usage_unit', { initialValue: _vm.limitUsageUnit }]),expression:"['limit_usage_unit', { initialValue: limitUsageUnit }]"}],staticStyle:{"width":"65px"}},_vm._l((_vm.limitUsageUnitOptions),function(option){return _c('a-select-option',{key:option.value,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1)],1):_vm._e()],1)],1),_c('a-form-item',{attrs:{"label":"是否可续期订购"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['renewable',{
                rules: [
                  { required: true, message: '请选择是否可续期订购' } ]
              }]),expression:"['renewable',{\n                rules: [\n                  { required: true, message: '请选择是否可续期订购' },\n                ]\n              }]"}],attrs:{"disabled":_vm.userBusinessType == 'merchant_sales'}},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" 是 ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" 否 ")])],1)],1),_c('a-form-item',{attrs:{"label":"是否自动续期"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_auto_renew',{
                initialValue: false,
                rules: [
                  { required: true, message: '请选择是否自动续期' }
                ]
              }]),expression:"['is_auto_renew',{\n                initialValue: false,\n                rules: [\n                  { required: true, message: '请选择是否自动续期' }\n                ]\n              }]"}],attrs:{"disabled":_vm.userBusinessType == 'merchant_sales'}},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" 是 ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" 否 ")])],1)],1),_c('a-form-item',{attrs:{"label":"沉默期(月)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['silent_period', {
                rules: [
                  { required: true, message: '请输入沉默期'},
                  { type: 'integer', message: ("请输入 0 - " + _vm.maxSilentPeriod + " 之间的整数") },
                  { validator: _vm.silentPeriodValidator }
                ]
              }]),expression:"['silent_period', {\n                rules: [\n                  { required: true, message: '请输入沉默期'},\n                  { type: 'integer', message: `请输入 0 - ${maxSilentPeriod} 之间的整数` },\n                  { validator: silentPeriodValidator }\n                ]\n              }]"}],staticStyle:{"width":"100%"},on:{"change":_vm.calcSilentPeriodEndDate}})],1),_c('a-form-item',{attrs:{"label":"沉默期截止时间"}},[_c('a-spin',{attrs:{"spinning":_vm.loadingSilentPeriodEndDate}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['silent_period_end_date', {
                  rules: [
                    { required: true, message: '沉默期截止时间不能为空' }
                  ]
                }]),expression:"['silent_period_end_date', {\n                  rules: [\n                    { required: true, message: '沉默期截止时间不能为空' }\n                  ]\n                }]"}],attrs:{"disabled":""}})],1)],1),_c('a-form-item',{attrs:{"label":"沉默期截止行为"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['silent_period_end_operate',{
                rules: [
                  { required: true, message: '请选择沉默期到期行为' } ]
              }]),expression:"['silent_period_end_operate',{\n                rules: [\n                  { required: true, message: '请选择沉默期到期行为' },\n                ]\n              }]"}],attrs:{"disabled":_vm.userBusinessType == 'merchant_sales'}},[_c('a-radio',{attrs:{"value":0}},[_vm._v(" 激活 ")]),_c('a-radio',{attrs:{"value":1}},[_vm._v(" 提醒销卡 ")])],1)],1),_c('a-form-item',{attrs:{"label":"测试流量(MB)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['test_traffic', {
                rules: [
                  { required: true, message: '测试流量'}
                ]
              }]),expression:"['test_traffic', {\n                rules: [\n                  { required: true, message: '测试流量'}\n                ]\n              }]"}],staticStyle:{"width":"100%"},attrs:{"min":0,"max":102400,"step":1,"precision":1}})],1),_c('a-form-item',{attrs:{"label":"测试期(月)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['test_period', {
                rules: [
                  { required: true, message: '请输入测试期'},
                  { type: 'integer', message: ("请输入 0 - " + _vm.maxTestPeriod + " 之间的整数") },
                  { validator: _vm.testPeriodValidator }
                ]
              }]),expression:"['test_period', {\n                rules: [\n                  { required: true, message: '请输入测试期'},\n                  { type: 'integer', message: `请输入 0 - ${maxTestPeriod} 之间的整数` },\n                  { validator: testPeriodValidator }\n                ]\n              }]"}],staticStyle:{"width":"100%"},on:{"change":_vm.calcTestPeriodEndDate}})],1),_c('a-form-item',{attrs:{"label":"测试期截止时间"}},[_c('a-spin',{attrs:{"spinning":_vm.loadingTestPeriodEndDate}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['test_period_end_date', {
                  rules: [
                    { required: true, message: '测试期截止时间不能为空' }
                  ]
                }]),expression:"['test_period_end_date', {\n                  rules: [\n                    { required: true, message: '测试期截止时间不能为空' }\n                  ]\n                }]"}],attrs:{"disabled":""}})],1)],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.userBusinessType == 'merchant_sales'),expression:"userBusinessType == 'merchant_sales'"}],attrs:{"label":"空白卡自动激活套餐"}},[_c('a-form-item',{style:({ display: 'inline-block', width: '55%', marginBottom: 0 })},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['active_agent_product_id']),expression:"['active_agent_product_id']"}],attrs:{"loading":_vm.loadingActiveAgentProductOptions,"allow-clear":""}},_vm._l((_vm.ActiveAgentProductOptions),function(option){return _c('a-select-option',{key:option.id,attrs:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1),_c('a-form-item',{style:({ display: 'inline-block', width: '40%', marginLeft:'5px', marginBottom: 0 })},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['active_agent_product_cycles', {
                    rules: [
                      { required: _vm.isSelectedActiveProduct, message: '周期数'}
                    ]
                  }]),expression:"['active_agent_product_cycles', {\n                    rules: [\n                      { required: isSelectedActiveProduct, message: '周期数'}\n                    ]\n                  }]"}],attrs:{"min":1,"max":120,"step":1,"precision":0}}),_vm._v("周期 ")],1)],1)],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.userBusinessType == 'merchant_sales'),expression:"userBusinessType == 'merchant_sales'"}],attrs:{"label":"空白卡定套后沉默期"}},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['convert_silent_period', {
                  rules: [
                    { required: _vm.userBusinessType == 'merchant_sales', message: '周期数'}
                  ]
                }]),expression:"['convert_silent_period', {\n                  rules: [\n                    { required: userBusinessType == 'merchant_sales', message: '周期数'}\n                  ]\n                }]"}],attrs:{"min":0,"max":6,"step":1,"precision":0}}),_vm._v("月（客户自行激活/订购套餐后重置沉默期） ")],1)],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.userBusinessType == 'merchant_sales'),expression:"userBusinessType == 'merchant_sales'"}],attrs:{"label":"空白卡定套后限额"}},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['convert_limit_usage_value', {
                  rules: [
                    { required: _vm.userBusinessType == 'merchant_sales', message: '限额倍数'}
                  ]
                }]),expression:"['convert_limit_usage_value', {\n                  rules: [\n                    { required: userBusinessType == 'merchant_sales', message: '限额倍数'}\n                  ]\n                }]"}],attrs:{"min":0,"max":100000,"step":1,"precision":0}}),_vm._v("倍（同上,0不限/B端3倍/非B端1倍容量） ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }