<template>
  <div>
    <a-modal
      width="650px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="项目配置"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-row>
          <!--基本信息， 周期容量设置-->
          <a-col :span="24">
            <a-form-item label="运营商账户">
              <a-select
                v-decorator="['carrier_account_id', {
                  rules: [
                    { required: true, message: '请选择运营商账户' }
                  ]
                }]"
                allow-clear
                @focus="fetchCarrierAccountOptions(data.carrier_type_slug)"
                @change="handleCarrierAccountChange"
                :loading="loadingCarrierAccountOptions"
              >
                <a-select-option
                  v-for="option in carrierAccountOptions"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="运营商套餐">
              <a-select
                @change="handleCarrierProductChange"
                v-decorator="['carrier_product_id', {
                  rules: [
                    { required: true, message: '请选择运营商套餐' }
                  ]
                }]"
                :loading="loadingCarrierProductOptions"
                allow-clear
              >
                <a-select-option
                  v-for="option in carrierProductOptions"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="流量属性">
              <a-input disabled v-model="trafficProperty" />
            </a-form-item>

            <a-form-item v-if="directionalShow" label="VPDN分组ID">
              <a-select
                @change="handleVpdnChange"
                v-decorator="['vpdn_id', {
                  rules: [
                    { required: true, message: '请选择VPDN分组ID' }
                  ]
                }]"
                :loading="loadingVpdnOptions"
                allow-clear
              >
                <a-select-option
                  v-for="option in vpdnOptions"
                  :key="option.vpdn_id"
                  :value="option.vpdn_id"
                >
                  {{ option.vpdn_id }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item v-if="directionalShow" label="VPDN分组名称">
              <a-input disabled v-model="vpdnMap.name" />
            </a-form-item>

            <a-form-item v-if="directionalShow && form.getFieldValue('vpdn_id')!='99999_E'&& form.getFieldValue('vpdn_id')!='99999_A'" label="VPDN IP地址">
              <a-textarea
                disabled
                style="height: 100px"
                v-model.trim="vpdnMap.ips"
                placeholder=""
              />
              <div>总数：{{ vpdnMap.ips_count }}</div>
            </a-form-item>

            <a-form-item label="实名认证">
              <a-radio-group
                v-decorator="['is_need_verified',{
                  initialValue: false,
                  rules: [
                    { required: true, message: '请选择实名认证' },
                  ]
                }]"
              >
                <a-radio :value="true">
                  是
                </a-radio>
                <a-radio :value="false">
                  否
                </a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="是否限额">
              <a-input-group compact>
                <a-form-item :style="{ display: 'inline-block', width: '15%', marginBottom: 0 }">
                  <a-checkbox
                    v-decorator="['is_limit_usage',{
                      valuePropName: 'checked'
                    }]"
                  />
                </a-form-item>

                <a-form-item
                  :style="{ display: 'inline-block', width: '65%', marginBottom: 0 }"
                  v-if="isLimitUsage"
                >
                  <a-space>
                    <a-input-number
                      style="width: 200px"
                      :min="0.01"
                      :max="10000000"
                      :step="0.01"
                      :precision="2"
                      v-decorator="['limit_usage_value', {
                        initialValue: data.limit_usage_value,
                        rules: [
                          { required: isLimitUsage, message: '请输入限额' }
                        ]
                      }]"
                    />
                  </a-space>
                </a-form-item>

                <a-form-item
                  :style="{ display: 'inline-block', width: '20%', marginBottom: 0 }"
                  v-if="isLimitUsage"
                >
                  <a-space>
                    <a-select
                      v-decorator="['limit_usage_unit', { initialValue: limitUsageUnit }]"
                      style="width: 65px"
                    >
                      <a-select-option
                        v-for="option in limitUsageUnitOptions"
                        :key="option.value"
                        :value="option.value"
                      >
                        {{ option.name }}
                      </a-select-option>
                    </a-select>
                  </a-space>
                </a-form-item>
              </a-input-group>
            </a-form-item>

            <a-form-item label="是否可续期订购">
              <a-radio-group
                v-decorator="['renewable',{
                  rules: [
                    { required: true, message: '请选择是否可续期订购' },
                  ]
                }]"
                :disabled="userBusinessType == 'merchant_sales'"
              >
                <a-radio :value="true">
                  是
                </a-radio>
                <a-radio :value="false">
                  否
                </a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="是否自动续期">
              <a-radio-group
                v-decorator="['is_auto_renew',{
                  initialValue: false,
                  rules: [
                    { required: true, message: '请选择是否自动续期' }
                  ]
                }]"
                :disabled="userBusinessType == 'merchant_sales'"
              >
                <a-radio :value="true">
                  是
                </a-radio>
                <a-radio :value="false">
                  否
                </a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="沉默期(月)">
              <a-input-number
                style="width: 100%"
                @change="calcSilentPeriodEndDate"
                v-decorator="['silent_period', {
                  rules: [
                    { required: true, message: '请输入沉默期'},
                    { type: 'integer', message: `请输入 0 - ${maxSilentPeriod} 之间的整数` },
                    { validator: silentPeriodValidator }
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="沉默期截止时间">
              <a-spin :spinning="loadingSilentPeriodEndDate">
                <a-input
                  disabled
                  v-decorator="['silent_period_end_date', {
                    rules: [
                      { required: true, message: '沉默期截止时间不能为空' }
                    ]
                  }]"
                />
              </a-spin>
            </a-form-item>

            <a-form-item label="沉默期截止行为">
              <a-radio-group
                v-decorator="['silent_period_end_operate',{
                  rules: [
                    { required: true, message: '请选择沉默期到期行为' },
                  ]
                }]"

                :disabled="userBusinessType == 'merchant_sales'"
              >
                <a-radio :value="0">
                  激活
                </a-radio>
                <a-radio :value="1">
                  提醒销卡
                </a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="测试流量(MB)">
              <a-input-number
                style="width: 100%"
                :min="0"
                :max="102400"
                :step="1"
                :precision="1"
                v-decorator="['test_traffic', {
                  rules: [
                    { required: true, message: '测试流量'}
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="测试期(月)">
              <a-input-number
                style="width: 100%"
                @change="calcTestPeriodEndDate"
                v-decorator="['test_period', {
                  rules: [
                    { required: true, message: '请输入测试期'},
                    { type: 'integer', message: `请输入 0 - ${maxTestPeriod} 之间的整数` },
                    { validator: testPeriodValidator }
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="测试期截止时间">
              <a-spin :spinning="loadingTestPeriodEndDate">
                <a-input
                  disabled
                  v-decorator="['test_period_end_date', {
                    rules: [
                      { required: true, message: '测试期截止时间不能为空' }
                    ]
                  }]"
                />
              </a-spin>
            </a-form-item>

            <a-form-item label="空白卡自动激活套餐" v-show="userBusinessType == 'merchant_sales'">
              <a-form-item :style="{ display: 'inline-block', width: '55%', marginBottom: 0 }">
                <a-select
                  v-decorator="['active_agent_product_id']"
                  :loading="loadingActiveAgentProductOptions"
                  allow-clear
                >
                  <a-select-option
                    v-for="option in ActiveAgentProductOptions"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item :style="{ display: 'inline-block', width: '40%', marginLeft:'5px', marginBottom: 0 }">
                <a-space>
                  <a-input-number
                    :min="1"
                    :max="120"
                    :step="1"
                    :precision="0"
                    v-decorator="['active_agent_product_cycles', {
                      rules: [
                        { required: isSelectedActiveProduct, message: '周期数'}
                      ]
                    }]"
                  />周期
                </a-space>
              </a-form-item>
            </a-form-item>

            <a-form-item label="空白卡定套后沉默期" v-show="userBusinessType == 'merchant_sales'">
              <a-space>
                <a-input-number
                  :min="0"
                  :max="6"
                  :step="1"
                  :precision="0"
                  v-decorator="['convert_silent_period', {
                    rules: [
                      { required: userBusinessType == 'merchant_sales', message: '周期数'}
                    ]
                  }]"
                />月（客户自行激活/订购套餐后重置沉默期）
              </a-space>
            </a-form-item>

            <a-form-item label="空白卡定套后限额" v-show="userBusinessType == 'merchant_sales'">
              <a-space>
                <a-input-number
                  :min="0"
                  :max="100000"
                  :step="1"
                  :precision="0"
                  v-decorator="['convert_limit_usage_value', {
                    rules: [
                      { required: userBusinessType == 'merchant_sales', message: '限额倍数'}
                    ]
                  }]"
                />倍（同上,0不限/B端3倍/非B端1倍容量）
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findCarrierAccountOptions, findPurchasableCarrierAccountProductOptions, findVpdnOptions } from '@/api/common_options'
import {
  findAgentPurchaseItemConfigureForm,
  configureAgentPurchaseItem,
  calculateAgentPurchaseItemSilentPeriodEndDate,
  calculateAgentPurchaseItemTestPeriodEndDate
} from '@/api/agent_purchase_item'
import { findAgentsProducts } from '@/api/agents_product'

export default {
  name: 'ConfigureAgentPurchaseItem',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    this.requestCalculateSilentPeriodEndDate = this.$lodash.debounce(this.requestCalculateSilentPeriodEndDate, 400)
    return {
      data: {},
      vpdnMap: {},
      directionalShow: false,
      isSelectedActiveProduct: false,
      trafficPropertyName: {
        general: '通用',
        directional: '定向'
      },
      loadingVpdnOptions: false,
      vpdnOptions: [],
      trafficProperty: '',
      vpdnOptionsMap: {},
      carrierAccountProductOptionsMap: {},
      carrierAccountOptions: [],
      carrierProductOptions: [],
      ActiveAgentProductOptions: [],
      loadingCarrierAccountOptions: false,
      loadingCarrierProductOptions: false,
      loadingActiveAgentProductOptions: false,
      maxSilentPeriod: 60,
      loadingSilentPeriodEndDate: false,
      isLimitUsage: false,
      limitUsageUnit: '',
      limitUsageUnitOptions: [],
      testTraffic: 0,
      maxTestPeriod: 60,
      loadingTestPeriodEndDate: false,
      userBusinessType: '',
      submitting: false,
      form: this.$form.createForm(this, { name: 'agent_purchase_item', onValuesChange: this.onValuesChange })
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    onValuesChange(props, values) {
      if (values.is_limit_usage !== undefined) {
        this.isLimitUsage = values.is_limit_usage
      }

      if (values.carrier_account_id !== undefined) {
        this.fetchCarrierAccountOptions(this.data.carrier_type_slug)
        this.fetchCarrierAccountProductOptions(values.carrier_account_id, values.carrier_product_id)
        // 查询客户vpdn信息
        this.fetchfindVpdnOptions(this.data.agent_id)
      }

      if (values.active_agent_product_id !== undefined) {
        this.isSelectedActiveProduct = true
      } else {
        this.isSelectedActiveProduct = false
      }
    },

    // 沉默期验证
    silentPeriodValidator(rule, value, callback) {
      if (value < 0 || value > this.maxSilentPeriod) {
        // eslint-disable-next-line standard/no-callback-literal
        callback(`请输入 0 - ${this.maxSilentPeriod} 之间的整数`)
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    // 测试期验证
    testPeriodValidator(rule, value, callback) {
      if (value < 0 || value > this.maxTestPeriod) {
        // eslint-disable-next-line standard/no-callback-literal
        callback(`请输入 0 - ${this.maxTestPeriod} 之间的整数`)
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    // 测试流量验证
    testTrafficValidator(rule, value, callback) {
      if (value < 0 || value > 10) {
        // eslint-disable-next-line standard/no-callback-literal
        callback(`请输入 0 - 10 之间的整数`)
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    handleCarrierProductChange(carrierProductId) {
      // 清空bpdn信息
      this.vpdnMap = {}

      this.trafficProperty = this.trafficPropertyName[
        this.carrierAccountProductOptionsMap[carrierProductId]
          ? this.carrierAccountProductOptionsMap[carrierProductId].traffic_property
          : ''
      ]
      this.directionalShow =
        this.carrierAccountProductOptionsMap[carrierProductId] &&
        this.carrierAccountProductOptionsMap[carrierProductId].traffic_property ===
          'directional'

      // 定向流量   编辑的时候表单赋值
      if (this.directionalShow && this.data.vpdn_id) {
        this.$nextTick(() => {
          this.form.setFieldsValue({
            vpdn_id: this.data.vpdn_id
          })
        })
      }
    },

    // 计算沉默期截止时间
    calcSilentPeriodEndDate(silentPeriod) {
      this.loadingSilentPeriodEndDate = true
      this.form.setFieldsValue({
        silent_period_end_date: undefined
      })

      this.$nextTick(() => {
        this.form.validateFields(['silent_period'], (err, values) => {
          if (!err) {
            this.requestCalculateSilentPeriodEndDate(silentPeriod)
          }
        })
      })
    },

    // 发起请求计算沉默期截止时间(lodash debounce)
    requestCalculateSilentPeriodEndDate(silentPeriod) {
      calculateAgentPurchaseItemSilentPeriodEndDate(this.id, { silent_period: silentPeriod }).then((res) => {
        if (res.code === 0 && res.data.silent_period === silentPeriod) {
          this.form.setFieldsValue({
            silent_period_end_date: res.data.silent_period_end_date
          })
        }
        this.loadingSilentPeriodEndDate = false
      })
    },

    // 计算测试期截止时间
    calcTestPeriodEndDate(testPeriod) {
      this.loadingTestPeriodEndDate = true
      this.form.setFieldsValue({
        test_period_end_date: undefined
      })

      this.$nextTick(() => {
        this.form.validateFields(['test_period'], (err, values) => {
          if (!err) {
            this.requestCalculateTestPeriodEndDate(testPeriod)
          }
        })
      })
    },

    // 发起请求计算测试期截止时间(lodash debounce)
    requestCalculateTestPeriodEndDate(testPeriod) {
      calculateAgentPurchaseItemTestPeriodEndDate(this.id, { test_period: testPeriod }).then((res) => {
        if (res.code === 0 && res.data.test_period === testPeriod) {
          this.form.setFieldsValue({
            test_period_end_date: res.data.test_period_end_date
          })
        }
        this.loadingTestPeriodEndDate = false
      })
    },

    // 加载运营商账户选项
    fetchCarrierAccountOptions(carrierType) {
      if (carrierType) {
        this.loadingCarrierAccountOptions = true
        findCarrierAccountOptions(carrierType).then(res => {
          if (res.code === 0) {
            this.loadingCarrierAccountOptions = false
            this.carrierAccountOptions = res.data
          }
        })
      }
    },

    handleVpdnChange(vpdnId) {
      var data = this.vpdnOptionsMap[vpdnId]
      if (!data.edit) {
        data.ips = JSON.parse(data.ips).join('\n')
        data.edit = true
      }
      this.vpdnMap = data
    },

    // 运营商账户变更，加载运营商状态
    handleCarrierAccountChange(carrierAccountId) {
      this.loadingCarrierProductOptions = true
      this.loadingActiveAgentProductOptions = true
      // 清空选项
      this.carrierProductOptions = []
      this.ActiveAgentProductOptions = []
      this.carrierAccountProductOptionsMap = []
      this.trafficProperty = ''
      this.vpdnMap = {}
      // 清空选项选中的值
      this.form.setFieldsValue({
        carrier_product_id: undefined,
        vpdn_id: undefined,
        active_agent_product_id: undefined
      })

      this.fetchCarrierAccountProductOptions(carrierAccountId)
    },

    fetchCarrierAccountProductOptions(carrierAccountId, carrierProductId) {
      // 加载运营商套餐选项
      if (carrierAccountId) {
        // 清空选项
        this.carrierAccountProductOptionsMap = []
        this.trafficProperty = ''
        this.vpdnMap = {}

        this.loadingCarrierProductOptions = true
        findPurchasableCarrierAccountProductOptions(carrierAccountId).then(res => {
          if (res.code === 0) {
            this.carrierProductOptions = res.data
            this.loadingCarrierProductOptions = false
            res.data.forEach((element) => {
              this.carrierAccountProductOptionsMap[element.id] = element
            })

            // 处理vpdn相关信息
            if (carrierProductId !== 0) {
              this.handleCarrierProductChange(carrierProductId)
            }

            if (carrierAccountId) {
              this.fetchCarrierActivedAccountProductOptions(carrierAccountId)
            }
          }
        })
      }
    },

    fetchCarrierActivedAccountProductOptions(carrierAccountId) {
      // 加载运营商激活套餐选项
      if (carrierAccountId) {
        // 清空选项
        this.loadingActiveAgentProductOptions = true
        findAgentsProducts({ agent_id: this.data.agent_id, carrier_type: this.data.carrier_type_slug }).then(res => {
          if (res.code === 0) {
            this.ActiveAgentProductOptions = res.data
          }
          this.loadingActiveAgentProductOptions = false
        })
      }
    },

    fetchfindVpdnOptions(agentId) {
      // 加载客户vpdn选项
      if (agentId) {
        this.loadingVpdnOptions = true
        findVpdnOptions(agentId).then(res => {
          if (res.code === 0) {
            this.vpdnOptions = res.data
            this.loadingVpdnOptions = false
            res.data.forEach((element) => {
              this.vpdnOptionsMap[element.vpdn_id] = element
            })

            // vpdn相关信息处理 因为select change事件导致先手顺序问题，这边就没搞回调去写，用延迟方便一点
            if (this.data.vpdn_id !== 0 && this.data.vpdn_id !== '') {
              setTimeout(() => {
                this.handleVpdnChange(this.data.vpdn_id)
              }, 100)
            }
          }
        })
      }
    },

    fetchData() {
      findAgentPurchaseItemConfigureForm(this.id).then((res) => {
        this.data = res.data

        this.data.agent_id = res.data.agent_id
        this.data.vpdn_id = res.data.vpdn_id
        this.userBusinessType = res.data.user_business_type

        if (this.data.product_capacity_unit === 'count') {
          this.limitUsageUnitOptions = [{ name: '次', value: 'count' }]
        } else {
          this.limitUsageUnitOptions = [
            { name: 'MB', value: 'mb' },
            { name: 'GB', value: 'gb' }
          ]
        }

        this.limitUsageUnit = res.data.limit_usage_unit

        if (res.data.limit_usage_value === 0) {
          res.data.limit_usage_value = undefined
        }

        if (res.data.active_agent_product_cycles === 0) {
          res.data.active_agent_product_cycles = undefined
        }

        this.form.setFieldsValue({
          carrier_account_id: res.data.carrier_account_id === 0 ? undefined : res.data.carrier_account_id,
          carrier_product_id: res.data.carrier_product_id === 0 ? undefined : res.data.carrier_product_id,
          is_need_verified: res.data.is_need_verified,
          is_limit_usage: res.data.is_limit_usage,
          renewable: res.data.renewable,
          is_auto_renew: res.data.is_auto_renew,
          silent_period: res.data.silent_period,
          silent_period_end_date: res.data.silent_period_end_date,
          silent_period_end_operate: res.data.silent_period_end_operate,
          active_agent_product_id: res.data.active_agent_product_id === 0 ? undefined : res.data.active_agent_product_id,
          active_agent_product_cycles: res.data.active_agent_product_cycles,
          test_traffic: res.data.test_traffic,
          test_period: res.data.test_period,
          test_period_end_date: res.data.test_period_end_date,
          convert_silent_period: res.data.convert_silent_period,
          convert_limit_usage_value: res.data.convert_limit_usage_value
        })
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          configureAgentPurchaseItem(this.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
